// src/pages/UserCreate.ts

import React, { useEffect, useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { addExpense } from "../redux/slices/addExpenseSlice";
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";

const today = dayjs();

interface Errors {
  [key: string]: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
  // Navigate to the clicked breadcrumb
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

const NewExpense = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { expense } = useSelector((state: any) => state.addExpense);
  const { options, loading } = useSelector(
    (state: any) => state.getDonationOptions
  );
  const [numericAmount, setNumericAmount] = useState<number | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(null);

  const [click, setClick] = useState(false);

  useEffect(() => {
    dispatch(getDonationOptions());
  }, []);

  useEffect(() => {
    if (expense && click) navigate("/expenses");
  }, [expense]);

  const [data, setData] = useState({
    by: "",
    amount: "",
    date: "",
    purpose: "",
    event: "",
    head: "",
    note: "",
  });

  const [errors, setErrors] = useState<Errors>({});
  // const textHandler = (e: any, input: any) => {
  //   setData({
  //     ...data,
  //     [input]: e.target.value, // Keep it as a string for validation purposes
  //   });
  //   console.log("Updated Data:", value);
  // };

  const textHandler = (e: any, input: any) => {  
    const newValue = e.target.value;  
    setData(prevData => ({  
      ...prevData,  
      [input]: newValue,  
    }));  
      
    if (input === 'amount') {  
      if (/^\d+(\.\d{1,2})?$/.test(newValue.trim())) {  
        setNumericAmount(parseFloat(newValue.trim()));  
      } else {  
        setNumericAmount(null);  
      }  
    }  
    
    console.log("Updated Data:", data);  
  };  
  

  const autoHandler = (e: any, value?: any, input?: any) => {
    setData({
      ...data,
      [input]: value, // Keep it as a string for validation purposes
    });
    console.log("Updated Data:", value);
  };

  const dateHandler = (e: any, input: any) => {
    // Ensure that the date is not null before formatting
    if (e) {
      const formattedDate = moment(e.toISOString());
      setData({
        ...data,
        [input]: formattedDate,
      });
      console.log("Updated Data:", data);
    } else {
      setData({
        ...data,
        [input]: "",
      });
      console.log("Updated Data:", data);
    }
  };

  console.log("data", data);

  const validateData = (): boolean => {
    let tempErrors: Errors = {};
    let isValid = true;

    // Name validation: Mandatory field
    if (!data.by.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
      console.log("FALSE HERE");
    }

    // Amount validation: Mandatory field
    if (!data.amount.trim()) {
      isValid = false;
      tempErrors.amount = "Amount is required.";
      console.log("FALSE HERE");
    } else if (!/^\d+(\.\d{1,2})?$/.test(data.amount.trim())) {
      isValid = false;
      tempErrors.amount = "Invalid amount format.";
      console.log("FALSE HERE");
    } else {
      // Store the numeric value in a separate state variable
      setNumericAmount(parseFloat(data.amount.trim()));
    }

    // Transaction Date validation: Mandatory field
    if (!data.date) {
      isValid = false;
      tempErrors.date = "Date is required.";
      console.log("FALSE HERE");
    }

    // Set the collected errors
    setErrors(tempErrors);

    // Return the overall validity of the form data
    return isValid;
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid) {
      console.log("Numeric Amount - ",numericAmount)
      // When submitting, use the numericAmount state for the amount
      const submitData = {
        ...data,
        amount: numericAmount,
      };

      dispatch(addExpense(submitData));
      console.log("Data Submitted:", data);
      setClick(true);
    } else {
      console.log("Validation Errors:", errors);
      // Here you can display the errors in a way that's appropriate for your UI
      alert("There are errors in the form. Please fix them before submitting.");
    }
  };

  // const submitHandler = () => {
  //   setClick(true);
  //   dispatch(createUser(data));
  //   console.log("Data Submitted", data);
  // };

  return (
    <div className="user-create">
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/expenses"
            label="Expenses"
            onClick={() => navigate("/expenses")}
          />
          <StyledBreadcrumb label="New" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{
                flexGrow: 1,
              }}
              noValidate
              autoComplete="off"
              onSubmit={submitHandler}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Add new expense
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    label="By"
                    size="small"
                    variant="outlined"
                    required // Name is mandatory
                    fullWidth
                    error={!!errors.by}
                    helperText={errors.by}
                    onChange={(e) => textHandler(e, "by")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={value}
                      label="Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          required: true,
                          error: !!errors.date,
                          helperText: errors.date,
                        },
                      }} // Make the input required
                      minDate={dayjs("2024-01-01T00:00:00.000")}
                      views={["year", "month", "day"]}
                      onChange={(e: any) => dateHandler(e, "date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    required // Amount is mandatory
                    fullWidth
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e) => textHandler(e, "amount")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.expense_purpose.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) => autoHandler(e, value, "purpose")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purpose"
                        size="small"
                        error={!!errors.purpose}
                        helperText={errors.purpose}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.events.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) => autoHandler(e, value, "event")}
                    renderInput={(params) => (
                      <TextField {...params} label="Event" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.heads.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) => autoHandler(e, value, "head")}
                    renderInput={(params) => (
                      <TextField {...params} label="Head" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.note}
                    helperText={errors.note}
                    onChange={(e) => textHandler(e, "note")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(NewExpense);
