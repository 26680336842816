import { Typography, Button, Box, Container, useTheme } from '@mui/material';  
import { useNavigate } from 'react-router-dom'; // assuming you're using react-router for navigation  
  
const PageNotFound = () => {  
    const navigate = useNavigate(); 
    const theme = useTheme();
  
    return (  
        <Container component="main" maxWidth="sm">  
            <Box  
                sx={{  
                    minHeight: '100vh',  
                    display: 'flex',  
                    flexDirection: 'column',  
                    justifyContent: 'center',  
                    alignItems: 'center',  
                    textAlign: 'center',  
                    p: theme.spacing(2)  
                }}  
            >  
                <Typography component="h1" variant="h3" gutterBottom>  
                    404  
                </Typography>  
                <Typography variant="h6" color="textSecondary">  
                    Oops! The page you're looking for isn't here.  
                </Typography>  
                <Typography variant="subtitle1" sx={{ mt: 1, mb: 4 }}>  
                    You might have the wrong address, or the page may have moved.  
                </Typography>  
                <Button   
                    variant="outlined"  
                    color="primary"  
                    sx={{ mt: 2 }}  
                    onClick={() => navigate('/')}  
                >  
                    Back to Home  
                </Button> 
            </Box>  
        </Container>  
    );  
};  
  
export default PageNotFound;  
