import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { title } from "process";

const NewSideBar = () => {
  const location = useLocation();
  const isActiveLink = (itemUrl:any) => {
    return location.pathname.startsWith(itemUrl);
  };

  return (
    <div className="new-side-bar">
      <div className="expended-view">
        <div className="item">
          {url?.map((item: any) => (
            <Link
              key={item.title}
              to={item.urlLink}
              className={
                isActiveLink(item.urlLink) ? "top-head active" : "top-head"
              }
            >
              {item?.icon}
              {item?.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewSideBar;

const url = [
  {
    title: "Donations",
    icon: <Icon icon="lucide:hand-heart" className="icons" />,
    urlLink: "/donations",
  },
  {
    title: "Expenses",
    icon: <Icon icon="lucide:credit-card" className="icons" />,
    urlLink: "/expenses",
  },
  {
    title: "Reports",
    icon: <Icon icon="lucide:newspaper" className="icons" />,
    urlLink: "/reports",
  },
  {
    title: "Options",
    icon: <Icon icon="lucide:settings" className="icons" />,
    urlLink: "/options",
  }
];
