// src/middleware/apiMiddleware.ts  
import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';  
import { RootState } from '../redux/types';  
import { setRedirectPath } from '../redux/slices/redirectSlice';  
  
const apiMiddleware: Middleware<{}, RootState> = store => next => action => {  
  if (isRejectedWithValue(action)) {  
    const payload = action.payload as { statusCode?: number; message?: string };  
     if (payload.statusCode === 401) {  
        console.log("it's midlleware");
        // Redirect logic here  
        window.location.href = '/signin'; // Ensure the route is correct  
      //store.dispatch(setRedirectPath('/login'));  
    }  
  }  
  return next(action);  
};  
  
export default apiMiddleware;  
