// src/redux/donationData/alldonationData.ts

import getDonationData from "./getDonationDataSlice";
import addDonationSlice from "./addDonationSlice";
import getExpenseDataSlice from "./getExpenseDataSlice";
import addExpenseSlice from "./addExpenseSlice";
import getUserSlice from "./getUserSlice";
import getDonationOptionsSlice from "./options/getDonationOptionsSlice";
import updateDonationEventOptionsSlice from "./options/updateDonationEventOptionsSlice";
import updateDonationGiftOptionsSlice from "./options/updateDonationGiftOptionsSlice";
import updateDonationHeadOptionsSlice from "./options/updateDonationHeadOptionsSlice";
import updateDonationPurposeOptionsSlice from "./options/updateDonationPurposeOptionsSlice";
import redirectSlice from "./redirectSlice";
  
export const allSlices = {
  getDonations: getDonationData,
  addDonation: addDonationSlice,
  getExpenses: getExpenseDataSlice,
  addExpense: addExpenseSlice,
  getUser: getUserSlice,
  getDonationOptions: getDonationOptionsSlice,
  updateDonationEventOptions: updateDonationEventOptionsSlice,
  updateDonationGiftOptions: updateDonationGiftOptionsSlice,
  updateDonationHeadOptions: updateDonationHeadOptionsSlice,
  updateDonationPurposeOptions: updateDonationPurposeOptionsSlice,
  redirect: redirectSlice,
};
