import React, { useEffect } from "react";  
import { useAppDispatch} from "../redux/hooks";  
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";  
import { getUser } from "../redux/slices/getUserSlice";  
import { Button, CircularProgress, Typography, Box, Container, Paper } from "@mui/material";  
import { styled } from '@mui/material/styles';  
import { Icon } from '@iconify/react';  
import { teal } from '@mui/material/colors'; 
  
// Styling for the main container  
const MainContainer = styled(Paper)({  
  height: '100vh',  
  display: 'flex',  
  alignItems: 'center',  
  justifyContent: 'center',  
  backgroundImage: 'linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)', // Gradient background  
  width: '100%', // Ensure it covers full width  
  margin: 0, // Remove default margins  
  padding: 0, // Remove default padding  
  borderRadius: 0 // Eliminate border radius for full screen usage  
});  
  
// Paper component for form area  
const FormPaper = styled(Paper)({  
  padding: '40px',  
  display: 'flex',  
  flexDirection: 'column',  
  alignItems: 'center',  
  boxShadow: '0 4px 10px rgba(0,0,0,0.2)',  
});  
  
// Custom styled button  
const StyledButton = styled(Button)({  
  backgroundColor: '#ffffff',  
  color: '#5F6368',  
  textTransform: 'none',  
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.25)',  
  '&:hover': {  
    backgroundColor: '#f8f9fa',  
  },  
  '& .MuiButton-startIcon': {  
    marginRight: 8,  
    color: '#4285F4',  
  },  
});  
  
const Signin = () => {  
  const dispatch = useAppDispatch();  
  const navigate = useNavigate();  
  const { user, loading } = useSelector((state: any) => state.getUser);  
  
  useEffect(() => {  
    dispatch(getUser());  
  }, [dispatch]);  
  
  useEffect(() => {  
    if (user) {  
      navigate("/");  
    }  
  }, [user, navigate]);  
  
  const createLoginUrl = (): string => {  
    const currentUrl = window.location.href;  
    const redirectUrl = encodeURIComponent(currentUrl);  
    const loginBaseUrl = `${process.env.REACT_APP_API_BASE}/signin`;  
    return `${loginBaseUrl}?redirectUrl=${redirectUrl}`;  
  };  
  
  return (  
    <MainContainer>  
      <FormPaper elevation={3}>  
        <Typography component="h1" variant="h4" color="#173161" gutterBottom>  
          Iskcon Bhism  
        </Typography>  
        <Typography component="p" sx={{ mb: 2 }} color="grey.700">  
          Sign in to your account  
        </Typography>  
        <Box mt={2}>  
          {loading ? (  
            <CircularProgress />  
          ) : (  
            <StyledButton  
              variant="contained"  
              fullWidth  
              href={createLoginUrl()}  
              startIcon={<Icon icon="logos:google-icon" />}  
            >  
              Sign in with Google  
            </StyledButton>  
          )}  
        </Box>  
      </FormPaper>  
    </MainContainer>  
  );  
};  
  
export default Signin;  