// src/pages/Profile.tsx
import React, { useEffect } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import TableBody from "../components/Dashboard/TableBody";
import { useAppDispatch } from "../redux/hooks";
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";

import { getUser } from "../redux/slices/getUserSlice";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";



const Profile = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: any) => state.getUser);

  useEffect(() => {
    dispatch(getUser());
  }, []);

 console.log("user", user);

  return (
    <div className="dashboard">
      hello
      <p>Name: {user?.name}</p>
      <p>Email: {user?.email}</p>
    </div>
  );
};

export default Profile;
