// src/redux/donationData/getUserSlice.ts

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;

export const updateDonationGiftOptions = createAsyncThunk("updateDonationGiftOptions", async (giftOptions: any) => {

    const { data } = await axios.post(`${API_BASE}/donation/options/gift`, giftOptions, {
      withCredentials: true, // This ensures cookies are sent with the request
    });
    return data;

});

const updateDonationGiftOptionsSlice = createSlice({
  name: "updateDonationGiftOptions",
  initialState: {
    loading: false,
    optionsUpdate: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateDonationGiftOptions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateDonationGiftOptions.fulfilled, (state, action) => {
      state.loading = false;
      state.optionsUpdate = action.payload;
    });
    builder.addCase(updateDonationGiftOptions.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default updateDonationGiftOptionsSlice.reducer;
