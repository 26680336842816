// src/redux/slices/redirectSlice.ts  
import { createSlice, PayloadAction } from '@reduxjs/toolkit';  
  
interface RedirectState {  
  path: string | null;  
}  
  
const initialState: RedirectState = {  
  path: null  
};  
  
const redirectSlice = createSlice({  
  name: 'redirect',  
  initialState,  
  reducers: {  
    setRedirectPath(state, action: PayloadAction<string | null>) {  
      state.path = action.payload;  
    }  
  }  
});  
  
export const { setRedirectPath } = redirectSlice.actions;  
export default redirectSlice.reducer;  
