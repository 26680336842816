// src/redux/store.ts  

import { configureStore } from '@reduxjs/toolkit';  
import apiMiddleware from '../middleware/apiMiddleware';  
import redirectReducer from './slices/redirectSlice';  
import { allSlices } from './slices/allSlices';  
// import other reducers  
  
export const store = configureStore({  
  reducer: { 
    ...allSlices,
    redirect: redirectReducer,  
    // other reducers  
  },  
  middleware: (getDefaultMiddleware) =>  
    getDefaultMiddleware().concat(apiMiddleware),  
});  
