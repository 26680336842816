import React, { useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import {
  Button,
  TextField,
  Container,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Breadcrumbs
} from "@mui/material";
import { emphasize, styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { Icon } from "@iconify/react";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const Reports: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [reportType, setReportType] = useState<string>("monthly");
  const currentDate = dayjs(); // Current date using dayjs
  const minDate = dayjs("2024-01-01"); // Minimum date set to January 2024

  const handleDateChange = (date: Dayjs | null): void => {
    setSelectedDate(date);
  };

  const handleReportTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newReportType: string
  ): void => {
    if (newReportType !== null) {
      setReportType(newReportType);
    }
  };

  const getReportUrl = (): string => {
    const year = selectedDate?.year() ?? dayjs().year();
    if (reportType === "yearly") {
      return `http://localhost:8082/report/donation/yearly-report/${year}`;
    } else {
      const month = (selectedDate?.month() ?? dayjs().month()) + 1; // month() returns 0-11, adjust to 1-12 for API
      return `http://localhost:8082/report/donation/monthly-report/${year}/${month}`;
    }
  };

  return (
    <div className="dashboard">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb component="a" href="/reports" label="Reports" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="sm">
        <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
          Download Donation Reports
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={reportType}
          exclusive
          onChange={handleReportTypeChange}
          sx={{ mb: 2 }}
        >
          <ToggleButton value="yearly">Yearly Report</ToggleButton>
          <ToggleButton value="monthly">Monthly Report</ToggleButton>
        </ToggleButtonGroup>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            label="Select Date"
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
              },
            }} // Make the input required
            views={reportType === "yearly" ? ["year"] : ["year", "month"]}
            maxDate={currentDate}
            minDate={minDate} // Set minDate to January 2024
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <a href={getReportUrl()} download>
              <Button variant="contained" color="primary" fullWidth>
                Download Report
              </Button>
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default layoutTwo(Reports);
