import NewSideBar from "../Nav/NewSideBar";
import Header from "../Nav/Header";

export default function layoutTwo(Component: any) {
  function LayoutTwo(props: any) {
    return (
      <div className="layout-two">
        <Header />
        <div className="page">
          <div className="page-left">
            <NewSideBar />
          </div>
          <div className="page-right">
            <Component {...props} />
          </div>
        </div>
      </div>
    );
  }

  return LayoutTwo;
}
