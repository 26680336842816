// src/pages/UserCreate.ts

import React, { useEffect, useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { addDonation } from "../redux/slices/addDonationSlice";
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from "@mui/base/Unstable_NumberInput";

const today = dayjs();

interface Errors {
  [key: string]: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
  // Navigate to the clicked breadcrumb
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

const NewDonation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { donation } = useSelector((state: any) => state.addDonation);
  const { options, loading } = useSelector(
    (state: any) => state.getDonationOptions
  );
  const [numericAmount, setNumericAmount] = useState<number | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(null);

  const [click, setClick] = useState(false);

  useEffect(() => {
    dispatch(getDonationOptions());
  }, []);

  useEffect(() => {
    if (donation && click) navigate("/donations");
  }, [donation]);

  const [data, setData] = useState({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    cash_chq_receive_date: "",
    amount: "",
    payment_mode: "",
    purpose: "",
    event: "",
    tr_no: "",
    head: "",
    pan: "",
    email: "",
    whatsapp_group: "",
    cash_deposit_date: "",
    gift: "",
  });

  const [errors, setErrors] = useState<Errors>({});

  // const textHandler = (e: any, input: any) => {
  //   setData({
  //     ...data,
  //     [input]: e.target.value, // Keep it as a string for validation purposes
  //   });
  //   console.log("Updated Data:", value);
  // };

  const textHandler = (e: any, input: any) => {  
    const newValue = e.target.value;  
    setData(prevData => ({  
      ...prevData,  
      [input]: newValue,  
    }));  
      
    if (input === 'amount') {  
      if (/^\d+(\.\d{1,2})?$/.test(newValue.trim())) {  
        setNumericAmount(parseFloat(newValue.trim()));  
      } else {  
        setNumericAmount(null);  
      }  
    }  
    
    console.log("Updated Data:", data);  
  };  
  

  const autoHandler = (e: any, value?: any, input?: any) => {
    setData({
      ...data,
      [input]: value, // Keep it as a string for validation purposes
    });
    console.log("Updated Data:", value);
  };

  const dateHandler = (e: any, input: any) => {
    // Ensure that the date is not null before formatting
    if (e) {
      const formattedDate = moment(e.toISOString());
      setData({
        ...data,
        [input]: formattedDate,
      });
      console.log("Updated Data:", data);
    } else {
      setData({
        ...data,
        [input]: "",
      });
      console.log("Updated Data:", data);
    }
  };
  console.log("data", data);
  const validateData = (): boolean => {
    let tempErrors: Errors = {};
    let isValid = true;

    // Name validation: Mandatory field
    if (!data.name.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
      console.log("FALSE HERE");
    }
    // else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(data.name)) {
    //   isValid = false;
    //   tempErrors.name = "Invalid name format.";
    //   console.log("FALSE HERE");
    // }

    // Phone validation: Mandatory field
    if (!data.phone.trim()) {
      isValid = false;
      tempErrors.phone = "Phone number is required.";
      console.log("FALSE HERE");
    } else if (!/^[0-9]{10}$/.test(data.phone)) {
      isValid = false;
      tempErrors.phone = "Invalid phone number. Must be 10 digits.";
      console.log("FALSE HERE");
    }

    // Amount validation: Mandatory field
    if (!data.amount.trim()) {
      isValid = false;
      tempErrors.amount = "Amount is required.";
      console.log("FALSE HERE");
    } else if (!/^\d+(\.\d{1,2})?$/.test(data.amount.trim())) {
      isValid = false;
      tempErrors.amount = "Invalid amount format.";
      console.log("FALSE HERE");
    } else {
      // Store the numeric value in a separate state variable
      setNumericAmount(parseFloat(data.amount.trim()));
    }

    // Transaction Date validation: Mandatory field
    if (!data.cash_chq_receive_date) {
      isValid = false;
      tempErrors.cash_chq_receive_date = "Transaction date is required.";
      console.log("FALSE HERE");
    }

    // // Payment Mode validation: Mandatory field
    // if (!data.payment_mode.trim()) {
    //   isValid = false;
    //   tempErrors.payment_mode = "Payment mode is required.";
    //   console.log("FALSE HERE");
    // }

    // Address validation: Optional field, validate if present
    if (data.address == "") {
      isValid = false;
      tempErrors.address = "Invalid address format.";
      console.log("FALSE HERE");
    }

    // City validation: Optional field, validate if present
    if (data.city == "") {
      isValid = false;
      tempErrors.city = "Invalid city format.";
      console.log("FALSE HERE");
    }

    // State validation: Optional field, validate if present
    if (data.state == "") {
      isValid = false;
      tempErrors.state = "Invalid state format.";
      console.log("FALSE HERE");
    }
    // Pincode validation: Optional field, validate if present
    if (data.pincode.trim() && !/^\d{6}$/.test(data.pincode)) {
      isValid = false;
      tempErrors.pincode = "Invalid pincode format.";
      console.log("FALSE HERE");
    }

    // PAN validation: Optional field, validate if present
    if (data.pan.trim() && !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(data.pan)) {
      isValid = false;
      tempErrors.pan = "Invalid PAN number format.";
      console.log("FALSE HERE");
    }

    // Email validation: Optional field, validate if present
    if (
      data.email.trim() &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)
    ) {
      isValid = false;
      tempErrors.email = "Invalid email format.";
      console.log("FALSE HERE");
    }

    // Tax Receipt No. validation: Optional field, validate if present
    if (data.tr_no.trim() && !/^\d+$/.test(data.tr_no)) {
      isValid = false;
      tempErrors.tr_no = "Tax Receipt No. must contain only numbers.";
      console.log("FALSE HERE");
    }

    // Set the collected errors
    setErrors(tempErrors);

    // Return the overall validity of the form data
    return isValid;
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid) {
      // When submitting, use the numericAmount state for the amount
      const submitData = {
        ...data,
        amount: numericAmount,
      };

      dispatch(addDonation(submitData));
      console.log("Data Submitted:", data);
      setClick(true);
    } else {
      console.log("Validation Errors:", errors);
      // Here you can display the errors in a way that's appropriate for your UI
      alert("There are errors in the form. Please fix them before submitting.");
    }
  };

  // const submitHandler = () => {
  //   setClick(true);
  //   dispatch(createUser(data));
  //   console.log("Data Submitted", data);
  // };

  return (
    <div className="user-create">
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/donations"
            label="Donations"
            onClick={() => navigate("/donations")}
          />
          <StyledBreadcrumb label="New" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{
                flexGrow: 1,
              }}
              noValidate
              autoComplete="off"
              onSubmit={submitHandler}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Add new donation
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    variant="outlined"
                    required // Name is mandatory
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={(e) => textHandler(e, "name")}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    size="small"
                    variant="outlined"
                    required // Phone is mandatory
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onChange={(e) => textHandler(e, "phone")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={value}
                      label="Transaction Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          required: true,
                          error: !!errors.cash_chq_receive_date,
                          helperText: errors.cash_chq_receive_date,
                        },
                      }} // Make the input required
                      minDate={dayjs("2024-01-01T00:00:00.000")}
                      views={["year", "month", "day"]}
                      onChange={(e: any) =>
                        dateHandler(e, "cash_chq_receive_date")
                      }
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    required // Amount is mandatory
                    fullWidth
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e) => textHandler(e, "amount")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="payment-mode-autocomplete"
                    options={paymentModes}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) =>
                      autoHandler(e, value, "payment_mode")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Mode"
                        size="small"
                        required // Payment Mode is mandatory
                        error={!!errors.payment_mode}
                        helperText={errors.payment_mode}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.purpose.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "purpose")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purpose"
                        size="small"
                        error={!!errors.purpose}
                        helperText={errors.purpose}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Address"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={(e) => textHandler(e, "address")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="City"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.city}
                    helperText={errors.city}
                    onChange={(e) => textHandler(e, "city")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="state-autocomplete"
                    options={states}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "state")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="State" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Pincode"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    onChange={(e) => textHandler(e, "pincode")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="PAN"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.pan}
                    helperText={errors.pan}
                    onChange={(e) => textHandler(e, "pan")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.events.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "event")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Event" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Tax Receipt No."
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.tr_no}
                    helperText={errors.tr_no}
                    onChange={(e) => textHandler(e, "tr_no")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.heads.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "head")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Head" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={(e) => textHandler(e, "email")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={value}
                      label="Deposit Date"
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(e: any) => dateHandler(e, "cash_deposit_date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.gifts.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value) => autoHandler(e, value, "gift")}
                    renderInput={(params) => (
                      <TextField {...params} label="Gift" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(NewDonation);

const paymentModes = ["Cash", "Cheque", "Online"];

const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
