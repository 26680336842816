import "./App.css";

import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React from "react";

import Dashboard from "./pages/Dashboard";
import NewDonation from "./pages/NewDonation";
import ExpenseDashboard from "./pages/ExpenseDashboard";
import NewExpense from "./pages/NewExpense";
import Signin from "./pages/Signin";
import Profile from "./pages/Profile";
import Options from "./pages/Options";
import Reports from "./pages/Reports";
import GiftOptions from "./pages/GiftOptions";
import HeadOptions from "./pages/HeadOptions";
import PurposeOptions from "./pages/PurposeOptions";
import EventOptions from "./pages/EventOptions";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/donations" />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/donations" element={<Dashboard />} />
        <Route path="/donations/new" element={<NewDonation />} />
        <Route path="/expenses" element={<ExpenseDashboard />} />
        <Route path="/expenses/new" element={<NewExpense />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/options" element={<Options />} />
        <Route path="/options/gift" element={<GiftOptions />} />
        <Route path="/options/head" element={<HeadOptions />} />
        <Route path="/options/purpose" element={<PurposeOptions />} />
        <Route path="/options/event" element={<EventOptions />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
