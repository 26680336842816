// src/redux/donationData/createUserSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;

export const addDonation = createAsyncThunk(
  "addDonation",
  async (donation: any) => {
    const { data } = await axios.post(`${API_BASE}/donations`, donation, {
      withCredentials: true,
    });
    return data;
  }
);

const addDonationSlice = createSlice({
  name: "addDonation",
  initialState: {
    loading: false,
    donation: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addDonation.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addDonation.fulfilled, (state, action) => {
      state.loading = false;
      state.donation = action.payload;
    });
    builder.addCase(addDonation.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default addDonationSlice.reducer;
