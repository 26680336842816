import React, { useEffect, useState } from "react";
import { emphasize, styled } from "@mui/material/styles";
import { Breadcrumbs, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Icon } from "@iconify/react";
import layoutTwo from "../components/layout/layoutTwo";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

const Options: React.FC = () => {
  return (
    <div className="dashboard">
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb component="a" href="/options" label="Options" />
        </Breadcrumbs>
      </div>

      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
        <nav aria-label="main mailbox folders">
          <List>
            <ListItem disablePadding>
              <ListItemButton href="/options/gift">
                <ListItemIcon>
                  <Icon icon="lucide:gift" />
                </ListItemIcon>
                <ListItemText primary="Gift" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/options/event">
                <ListItemIcon>
                  <Icon icon="lucide:ticket" className="icons" />
                </ListItemIcon>
                <ListItemText primary="Event" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/options/head">
                <ListItemIcon>
                  <Icon icon="lucide:pocket" className="icons" />
                </ListItemIcon>
                <ListItemText primary="Head" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/options/purpose">
                <ListItemIcon>
                  <Icon icon="lucide:heart-handshake" className="icons" />
                </ListItemIcon>
                <ListItemText primary="Purpose" />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      </Box>
    </div>
  );
};

export default layoutTwo(Options);
