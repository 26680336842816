// src/redux/expenseData/createUserSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_BASE = process.env.REACT_APP_API_BASE;

export const addExpense = createAsyncThunk(
  "addExpense",
  async (expense: any) => {
    const { data } = await axios.post(`${API_BASE}/expenses`, expense, {
      withCredentials: true,
    });
    return data;
  }
);

const addExpenseSlice = createSlice({
  name: "addExpense",
  initialState: {
    loading: false,
    expense: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addExpense.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addExpense.fulfilled, (state, action) => {
      state.loading = false;
      state.expense = action.payload;
    });
    builder.addCase(addExpense.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default addExpenseSlice.reducer;
